<template>
    <div :data-template="content?.['mgnl:template']">
        <PageLayout>
            <EditorAnnotationElements v-if="isEditMode && !isComponentPreview()" />

            <Suspense>
                <EditablePage
                    v-if="content"
                    :key="content?.['@id']"
                    :content="content"
                    :config="config"
                    :template-annotations="templateAnnotations"
                />
                <!-- component with nested async dependencies -->
                <!-- loading state via #fallback slot -->
                <template #fallback>
                    <LoadingElement class="aspect-2/3 lg:aspect-video container" />
                </template>
            </Suspense>
        </PageLayout>
    </div>
</template>

<script setup>
import { isComponentPreview, isEditMode as evaluateEditMode, isMagnoliaPreview } from '~/utils/content/magnolia';
import { componentMappings } from '~/utils/config/components';
import { DefaultFallbackComponent, EditablePage } from '@magnolia/vue-editor';
import { onMounted, provide, ref } from 'vue';
import useMagnoliaContent from '~/composables/useMagnoliaContent';
import useMagnoliaContext from '~/composables/useMagnoliaContext';
import useMarketContent from '~/composables/useMarketContent';
import useMyBritaUser from '~/composables/useMyBritaUser';
import PageLayout from '~/templates/partials/PageLayout';
import EditorAnnotationElements from '~/templates/elements/editor/EditorAnnotationElements.vue';
import LoadingElement from '~/templates/elements/LoadingElement.vue';

const isEditMode = evaluateEditMode();

const config = {
    componentMappings,
    fallbackComponent: DefaultFallbackComponent,
};

const { initMagnoliaContext, loadTemplateAnnotations, verifyMagnoliaContext, contentPath } = useMagnoliaContext();
const { loadMarketContent } = useMarketContent();
const { initLanguages, defaultLanguage } = useMagnoliaLanguage();

if (import.meta.server || !defaultLanguage.value) {
    // init and verify magnolia context
    await verifyMagnoliaContext();
    // do this only on first request or when ssr is disabled
    await initLanguages();
    // init magnolia context from current url
    await initMagnoliaContext();
    // load market content
    await loadMarketContent(contentPath.value);
} else {
    // update magnolia on clientside navigations with current url
    await initMagnoliaContext();
}
// load content
const { loadContent, content, pageHeader, pageFooter, pageBanner } = useMagnoliaContent();
// load page content
await loadContent(contentPath.value);

// check content restrictions based on content
const { validateContent } = useMyBritaUser();

if (!isEditMode) {
    await validateContent(content);
}

// set page meta / seo information
usePageMeta();
// provide content
provide('content', content);
provide('pageHeader', pageHeader);
provide('pageFooter', pageFooter);
provide('pagePromoBanner', pageBanner);
// defaults
provide('fallbackComponent', DefaultFallbackComponent);

const templateAnnotations = ref({});

onMounted(async () => {
    useScrollTracking();
    if (isEditMode || isMagnoliaPreview()) {
        // call template annotation api from client
        templateAnnotations.value = await loadTemplateAnnotations();
    }
});
</script>

<script>
export default {
    name: 'SlugPage',
};
</script>
